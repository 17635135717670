import axios from "axios";
import { getJWTToken } from "./authservice";

const getItemdata = async (productID, metric, locale, subLocale) => {
    
    try {
        console.log(productID, metric, locale, subLocale);

        const token = await getJWTToken();
        const headers = {
            Authorization: `Bearer ${token}`,
        };
        // locale="en";
        // metric="metric"
        const response = await axios.get(`${process.env.REACT_APP_DATA_API}api/Items/productItem?ItemNo=${productID}&Locale=${locale}&Metric=${metric}`, {headers});
        if (response.status === 401) {
            return "somthing went wrong";
        }
        const itemData = await response.data;
        return itemData
    } catch (error) {
        console.error(error)
        throw new Error("Something went wrong");
    }
};

const getTechnicalData = async (productID, weighUnit, lengthUnit) =>{
 try
 {
    const token = await getJWTToken();
    const headers = {
        Authorization: `Bearer ${token}`,
    };
    const response = await axios.get(`${process.env.REACT_APP_DATA_API}api/Items/TechnicalData?ItemNo=${productID}&mms_inches=${lengthUnit}&gs_lbs=${weighUnit}`, {headers});
    if (response.status !== 200) {
        throw new Error("Something went wrong");
    }
    const itemData = await response.data;
    return itemData

 } catch(error){
    console.error(error)
    throw new Error("Something went wrong");
 }
}

const getImage = (productID, par, imgSize) => {
    return new Promise((resolve, reject) => {
      let imageUrl = '';
      if(imgSize==="small")
      {
        imageUrl= `${process.env.REACT_APP_IMAGE_API}?itemName=${productID}&itemPar=${par}&size=${imgSize}`;
      }
      else
      {
        imageUrl= `${process.env.REACT_APP_IMAGE_API}?itemName=${productID}&itemPar=${par}`;
      }
      
      axios.get(imageUrl, { responseType: 'blob' })
        .then(response => {
          const reader = new FileReader();
          reader.readAsDataURL(response.data); // Convert blob to base64
          reader.onloadend = () => resolve(reader.result);
          reader.onerror = error => reject(error);
        })
        .catch(error => {
          console.error('Error fetching the image:', error);
          reject(error);
        });
    });
  };

const brand_color = {
    "FGF": "#7ac141",
    "Stonefire": "Orange",
    "Ace": "Grey",
    "Simple Joys":"#5478d1"
}

const getBrandColor = (brand) => {
    console.log(Object.keys(brand_color))
    if (Object.keys(brand_color).includes(brand)) {
        return brand_color[brand]
    }
    else {
        return "Grey"
    }
}


const getDropdownOptions = () => {
    const options = [
        { value: 'chocolate', label: 'Chocolate' },
        { value: 'strawberry', label: 'Strawberry' },
        { value: 'vanilla', label: 'Vanilla' },
    ];

    return options
}

function sleep(ms) {
    return new Promise(resolve => setTimeout(resolve, ms));
}

const salesSheetTitles ={
    "en":{
    "Product Specifications":"Product Specifications",
    "Ingredients":"Ingredients",
    "Features & Benefits":"Features & Benefits",
    "Handling Instructions":"Handling Instructions"
    },
    "fr":{
    "Product Specifications":"Spécifications du produit",
    "Ingredients":"Ingrédients",
    "Features & Benefits":"Caractéristiques et avantages",
    "Handling Instructions":"Instructions de manipulation"
    }
}

const SalesSheetTitles_en = {
    "Product Specifications":"Product Specifications",
    "Ingredients":"Ingredients",
    "Features & Benefits":"Features & Benefits",
    "Handling Instructions":"Handling Instructions"
}

const SalesSheetTitles_fr = {
    "Product Specifications":"Spécifications du produit",
    "Ingredients":"Ingrédients",
    "Features & Benefits":"Caractéristiques et avantages",
    "Handling Instructions":"Instructions de manipulation"
}

const makeSalesSheetTitles=(titleName,locale, subLocale) =>{

    if(locale===subLocale || subLocale==="none")
    {
        return salesSheetTitles[locale][titleName];
    }
    else{

        return salesSheetTitles[locale][titleName] + "/" + salesSheetTitles[subLocale][titleName]
    }

}


const items = [{ "item_No": "8100796", "description": "MKS Mini Croissant Choc 8pk" },
{ "item_No": "8100798", "description": "AF Muffin AppCrml FL 4PK" },
{ "item_No": "8100799", "description": "FGF MiniMuffin BB 12PK" },
{ "item_No": "8100800", "description": "FGF MiniMuffin CC 12PK" },
{ "item_No": "8100801", "description": "FGF MiniMuffin BB/CC VP 12PK" }]

const muffin = { "item_Name": "SF FS Pizza Orig Thin 12\" Bulk", 
"item_No": "8100119", 
"item_UPC_EAN_Number": " 876681001199", 
"cross_Reference_No": "10876681001196", 
"pieces_Per_Pack": 32, 
"brand": "Stonefire", 
"pack_Per_Case": 1, 
"t_x_T": "4X11=44", 
"cubic_Feet": 47661.99, 
"refrigerated": "Ambient", 
"expiration_Calculation": "365", 
"ambient": "N/A", 
"case_Length_Outer": 65.723, 
"case_Width_Outer": 34.608, 
"case_Height_Outer": 20.955, 
"case_Label_Description": "12\" THIN PIZZA CRUST BULK", 
"packaging_description": "Plastic liner bag", 
"ingredients_en": "Ingredients: Enriched wheat flour (wheat flour, niacin, reduced iron, ascorbic acid added as dough conditioner, thiamine mononitrate, riboflavin, enzyme, folic acid), water, soybean and/or canola oil, cultured wheat flour, salt, sugar, dried sourdough (wheat flour, rye flour, yeast, ascorbic acid, amylase, xylanase), vegetable mono- and diglycerides, yeast, inactive yeast, extra virgin olive oil, amylase, durum wheat semolina.\r\nContains: wheat and rye. Produced in a facility that also uses eggs, milk, soy and sesame.\r\n", 
"ingredients_fr": "Ingrédients : Farine de blé enrichie, eau, huile de soya et/ou canola, farine de blé de culture, sel, sucre, levain en poudre (farine de blé, farine de seigle, levure, acid ascorbique, amylase, xylanase), mono- et diglycérides végétaux, levure, levure inactive, huile d’olive extra vierge, amylase, semoule de blé dur.\r\nContient : blé et siegle. Peut contenir : œufs, lait, soya et sésame.\r\n", 
"cust_Expected_Shelf_Days_Ambient": 7, 
"baked_Weight": 240, 
"unit_Weight": 7680, 
"net_Case_Weight": 7.68, 
"gross_Case_Weight": 8.18, 
"certification_Name": "Kosher Pareve, Vegan", 
"item_EAN_Number": "", 
"cust_Expected_Shelf_Days_Refridgerated": 0, 
"item_description": "", 
"features_and_benefits": "", 
"handling_Instructions": "",
"modified_Date": "2024-02-22",
"brand_Website": "www.Stonefire.com/Foodservices" }

export { getItemdata, getImage, getBrandColor, getDropdownOptions, sleep, getTechnicalData,makeSalesSheetTitles }