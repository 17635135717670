import React from 'react';
import '../styles/sales.css';
import SalesReport from '../components/SalesReport';
import { useState, useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import Select from "react-select/async";
import debounce from "debounce-promise";
import axios from "axios";
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDownload, faEye } from '@fortawesome/free-solid-svg-icons';
import { getJWTToken } from '../services/authservice';



const getOptions = debounce(async (inputValue) => {
    try {
        // Always fetch, but if there's no inputValue, don't use the 'name_like' filter
        const token = localStorage.getItem('ItemToken'); // Assuming the token is stored in localStorage
        // Set up Axios headers
        const headers = {
            Authorization: `Bearer ${token}`,
        };
        const query = inputValue ? `&searchText=${inputValue}` : "";

        const response = await axios.get(
            `${process.env.REACT_APP_DATA_API}api/Items?page=1&pageSize=10${query}`,
            {headers}
        );

        // Check if the response data is an array before proceeding
        if (Array.isArray(response.data)) {
            const items = response.data.slice(0, 10).map((item) => ({
                // Slice to get only 10 results
                value: item.item_No,
                label: item.item_No + " - " + item.description,
            }));
            return items;
        } else {
            // Handle the case where data is not an array
            console.error('Response data is not an array');
            return [];
        }
    } catch (error) {
        // Handle errors here
        console.error('There was an error fetching the options:', error);
        return [];
    }
}, 1000);

const SalesSheet = () => {
    const [metric, setMetric] = useState("metric");
    const [locale, setLocale] = useState("en");
    const [subLocale, setSubLocale] = useState("none");
    const [submit, setSubmit] = useState(false);
    const [searchParams, setSearchParams] = useSearchParams();
    const [Ddoptions, setDdoptions] = useState([])
    const [pid, setpID] = useState()
    const [exportPDF, setExportPDF] = useState(false)
    const [defaultOptions, setDefaultOptions] = useState([]);

    const exportTOPDF = () => {
        html2canvas(document.querySelector("#report"), { scale: 2 }).then(canvas => {
            const imgData = canvas.toDataURL('image/png');
            const pdf = new jsPDF('p', 'pt', 'a4', true, 16);
            const width = pdf.internal.pageSize.getWidth();
            const height = (canvas.height * width) / canvas.width;
            // const height = pdf.internal.pageSize.getHeight();
            // const width = canvas.width;
            // pdf.addImage(imgData, 'PNG', 0, 0, 600, 0, undefined, false);
            const pageWidth = pdf.internal.pageSize.getWidth();
            const pageHeight = pdf.internal.pageSize.getHeight();
    
            console.log(pageHeight, pageWidth)
    
            const widthRatio = pageWidth / canvas.width;
            const heightRatio = pageHeight / canvas.height;
            const ratio = widthRatio > heightRatio ? heightRatio : widthRatio;
    
            const canvasWidth = canvas.width * ratio;
            const canvasHeight = canvas.height * ratio;
    
            // const marginX = (pageWidth - canvasWidth) / 2;
            const marginX = 0
            const marginY = 0;
            // const marginY = (pageHeight - canvasHeight) / 2;
    
            pdf.addImage(imgData, 'PNG', marginX, marginY, width, height, '', 'FAST');
            pdf.save(`Sales_SpecSheet_${pid.value??''}.pdf`);
        });
    };

    const isPDFReady = (readyVal) => {
        setExportPDF(readyVal)
    }

    const handleOptions = (Ddoptions) => {
        setDdoptions(Ddoptions)
    }

    const handleChange = (e) => {
        isPDFReady(false)
        setSubmit(false)
        console.log(e.value)
        setpID({
            value: e.value,
            label: e.label, 
        });
    };

    const handleLocale = (e) => {
        isPDFReady(false)
        setSubmit(false)
        setLocale(e.target.value)
        searchParams.set("locale", e.target.value)
    };
    const handleSubLocale = (e) => {
        isPDFReady(false)
        setSubmit(false)
        setSubLocale(e.target.value)
    };

    const handleMetric = (e) => {
        isPDFReady(false)
        setSubmit(false)
        setMetric(e.target.value)
    };

    const handleSubmit = () => {
        if (pid) {
            setSubmit(true)
        }
        else {
            alert("product ID is required.")
        }
    }


    useEffect(() => {
        getJWTToken().then((data) => {
            localStorage.setItem("ItemToken", data)
        })
        // handleOptions(data)
        if (searchParams.get("id")) {
            const ParamID = searchParams.get("id")
            getOptions(ParamID).then((options) => {
                setpID({
                    value: options[0].value,
                    label: options[0].label
                })
            })
        }
        else{
            getOptions("").then((options) => {
                setDefaultOptions(options);
            })
        }

        if(searchParams.get("locale"))
        {
            const locale_param = searchParams.get("locale")
            const opts = document.getElementById("localeSelect").options; 
            const vals = Array.from(opts).map(el => el.value);

            if(vals.includes(locale_param))
            {
                document.getElementById("localeSelect").value=locale_param
                setLocale(locale_param)
            }
        }

        if(searchParams.get("sublocale"))
        {
            const sublocale_param = searchParams.get("sublocale")
            
            const opts = document.getElementById("sublocaleSelect").options; 
            // we can use forEach on the resulting HTMLCollection 
            // but map needs to be spread to array
            const vals = Array.from(opts).map(el => el.value);

            if(vals.includes(sublocale_param))
            {
                document.getElementById("sublocaleSelect").value=sublocale_param
                setSubLocale(sublocale_param)
            }

        }

    }, [searchParams]);

    return (
        <div className="container-fluid">
            <div className='containerInput mt-2'>
                <div className="row m-3">
                    <div className="col col-md-2 col-sm-12">
                        <span className='Inputtitles'>Product ID</span>
                        <Select
                            cacheOptions
                            defaultOptions={defaultOptions}
                            loadOptions={getOptions}
                            value={pid}
                            placeholder="Start typing to search..."
                            onChange={handleChange}
                        />
                    </div>
                    <div className="col col-md-2 col-12 mt-3">
                        <span className='Inputtitles'> Metric Imperial</span>
                        <select className="form-select selectbar" defaultValue={metric} onChange={handleMetric}>
                            <option value="metric">Metric</option>
                            <option value="imperial">Imperial</option>
                        </select>
                    </div>
                    <div className="col col-md-2 col-12 mt-3">
                        <span className='Inputtitles'>Locale</span>
                        <select id="localeSelect" className="form-select selectbar" defaultValue={locale} onChange={handleLocale}>
                            <option value="en" >English</option>
                            <option value="fr">French</option>
                        </select>
                    </div>
                    <div className="col col-md-2 col-12 mt-3">
                        <span className='Inputtitles'>Sub Locale</span>
                        <select id="sublocaleSelect" className="form-select selectbar" defaultValue={subLocale} onChange={handleSubLocale}>
                            <option value="none">None</option>
                            <option value="en">English</option>
                            <option value="fr">French</option>
                        </select>
                    </div>
                    <div className="col col-md-2 col-12 mt-3">
                        <button className='btn viewBtn' onClick={handleSubmit}>View report <FontAwesomeIcon icon={faEye} /></button>
                    </div>

                    <div className="col col-md-2 col-12 mt-3">
                        {exportPDF ? <button className='btn downBtn' onClick={exportTOPDF}>Download pdf <FontAwesomeIcon icon={faDownload} /></button> : null}
                    </div>

                </div>
            </div>
            {submit ? <SalesReport
                productID={pid.value}
                locale={locale}
                subLocale={subLocale}
                metric={metric}
                pdfReady={isPDFReady}
            /> : <p className="reportLoading">Please select item and click view report.</p>}


        </div>
    );
};

export default SalesSheet;