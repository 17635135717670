// In your LinkContext.js or equivalent file
import React, { createContext, useContext, useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';

const LinkContext = createContext();

export const useLink = () => useContext(LinkContext);

export const LinkProvider = ({ children }) => {
  const [activeLink, setActiveLink] = useState('');
  const location = useLocation();

  useEffect(() => {
    // Update the active link based on the current pathname
    const path = location.pathname.replace('/', '') || 'home';
    setActiveLink(path);
  }, [location]);

  const handleLinkClick = (link) => {
    setActiveLink(link);
  };

  return (
    <LinkContext.Provider value={{ activeLink, handleLinkClick }}>
      {children}
    </LinkContext.Provider>
  );
};
