import axios from "axios";
import { InteractionRequiredAuthError } from '@azure/msal-browser';
import CryptoJS from 'crypto-js';



const encryptUsername = (secretKey) => {

  const userEmail = localStorage.getItem("userEmail");

  if(!userEmail)
  {
    return "invalid" 
  }
  // Convert the secret key to a WordArray for consistent handling
  const key = CryptoJS.enc.Utf8.parse(secretKey);
  
  // Configure the encryption parameters including mode (ECB) and padding
  const cfg = {
    mode: CryptoJS.mode.ECB,
    padding: CryptoJS.pad.Pkcs7
  };
  
  // Perform the encryption
  const encrypted = CryptoJS.AES.encrypt(userEmail, key, cfg);
  
  // Return the Base64-encoded string
  return encrypted.ciphertext;
}

const getJWTToken = async () => {
  try {
    const encryptedUser = encryptUsername(process.env.REACT_APP_SECRET_KEY);
    if(encryptedUser==="invalid")
    {
      return "Somthing went wrong";
    }
    const response = await axios.get(`${process.env.REACT_APP_DATA_API}api/Token?username=${encryptedUser}`);

    if (response.status === 401) {
      return "somthing went wrong";
    }
    return response.data;

  } catch (error) {
    console.error(error)
    return "somthing went wrong";
  }
  //   setLoading(false);
};


const getUserProfile = async (instance) => {
  const activeAccount = instance.getActiveAccount();
  try {
    const accessToken = await instance.acquireTokenSilent({
      account: activeAccount,
      scopes: ['User.Read', 'User.ReadBasic.All']
    });

    // Make a request to Microsoft Graph API to get user profile details
    const response = await fetch('https://graph.microsoft.com/v1.0/me', {
      headers: {
        Authorization: `Bearer ${accessToken.accessToken}`,
      },
    });

    if (response.ok) {
      // const profileData = await response.json();
      const pdata = await response.json();
      return pdata
    } else {
      console.error('Failed to fetch user profile:', response.statusText);
      return "not found"
    }
  } catch (error) {
    console.error('Error fetching user profile:', error);
    if (error instanceof InteractionRequiredAuthError) {
      alert("Session expired. Please reload the page to re-authenticate.")
    }
    else {
      // Handle other errors
      console.error("Error acquiring token:", error);
    }
    return null
  }
};


const getProfilePhoto = async (instance) => {
  const activeAccount = instance.getActiveAccount();
  try {
    const accessToken = await instance.acquireTokenSilent({
      account: activeAccount,
      scopes: ['User.Read', 'User.ReadBasic.All']
    });

    // Make a request to Microsoft Graph API to get user profile details
    const response = await fetch('https://graph.microsoft.com/v1.0/me/photo/$value', {
      headers: {
        Authorization: `Bearer ${accessToken.accessToken}`,
      },
    });

    if (response.ok) {
      const blob = await response.blob();
      const url = URL.createObjectURL(blob);
      return url
    } else {
      console.error('Failed to fetch user profile:', response.statusText);
      return "not found"
    }
  } catch (error) {
    console.error('Error fetching user profile:', error);
    return null
  }
}


export { getJWTToken, getUserProfile, getProfilePhoto };