import React from 'react';
import { Link } from 'react-router-dom';
import FGFLogo from '../static/logos/FGF.png'
import '../styles/navbar.css'
import { useState } from 'react';
import { useMsal, useAccount } from '@azure/msal-react';
import { useEffect } from 'react';
import { useLink } from '../LinkContext';
import { InteractionRequiredAuthError } from '@azure/msal-browser';

const Navbar = () => {
  const { instance, accounts } = useMsal();
  const account = useAccount(accounts[0] || {});
  const [userName, setUserName] = useState('');
  const [userPhoto, setUserPhoto] = useState('');
  const [mail, setMail] = useState('');
  const [office, setOffice] = useState('');
  const [techNav, setTechNav] = useState(true);
  const { activeLink,handleLinkClick } = useLink();

  useEffect(() => {
    if (account) {
        instance.acquireTokenSilent({
          account: account,
          scopes: ['User.Read','User.ReadBasic.All']
        }).then(response => {
          localStorage.setItem("Access_Token", response.accessToken)
            // Fetch user details from Microsoft Graph API
            fetch('https://graph.microsoft.com/v1.0/me', {
                headers: {
                    'Authorization': `Bearer ${response.accessToken}`
                }
            })
            .then(resp => resp.json())
            .then(data => {
                setUserName(data.displayName);
                setMail(data.mail)
                localStorage.setItem("userEmail", data.mail)
                setOffice(data.officeLocation)
                // Fetch user photo
                return fetch('https://graph.microsoft.com/v1.0/me/photo/$value', {
                    headers: {
                        'Authorization': `Bearer ${response.accessToken}`
                    }
                });
            })
            .then(response => {
                if (response.ok) {
                    return response.blob();
                }
                // If there's no photo, set a default image or handle the absence of a photo
                throw new Error('No photo found');
            })
            .then(blob => {
                // Create a URL for the blob object
                setUserPhoto(URL.createObjectURL(blob));
            })
            .catch(error => {
                console.error(error);
                // Set a default image or handle the error
                setUserPhoto('path_to_default_image_if_needed');
            });
        }).catch(error=>{
          if(error instanceof InteractionRequiredAuthError)
          {
            alert("Session expired. Please reload the page to re-authenticate.")
          }
          else {
            // Handle other errors
            console.error("Error acquiring token:", error);
        }
        });
    }
}, [account, instance]);

    const handleLogout = () => {
    // Clear the user's session and sign out
    instance.logout();
  }

  return (
    <nav className="navbar navbar-expand-lg navbar-dark bg-dark navDark">
      <div className="container-fluid p-0">
        <a className="navbar-brand p-0" href="#">
          <img src={FGFLogo} width="66" height="66" className="d-inline-block align-top p-0" alt="FGF" />
        </a>
        <button
          className="navbar-toggler"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbarSupportedContent"
          aria-controls="navbarSupportedContent"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span className="navbar-toggler-icon"></span>
        </button>
        <div className="collapse navbar-collapse" id="navbarSupportedContent">
          <ul className="navbar-nav me-auto mb-2 mb-lg-0">
            <li className="nav-item">
              <Link
                className={`nav-link ${activeLink === 'home' ? 'active' : ''}`}
                to="/"
                onClick={() => handleLinkClick('home')}
              >Home</Link>
            </li>
            <li className="nav-item">
              <Link
                className={`nav-link ${activeLink === 'sales' ? 'active' : ''}`}
                to="/sales"
                onClick={() => handleLinkClick('sales')}
              >Sales SpecSheet</Link>
            </li>
            {techNav ? (<li className="nav-item">
              <Link
                className={`nav-link ${activeLink === 'tech' ? 'active' : ''}`}
                to="/tech"
                onClick={() => handleLinkClick('tech')}
              >Techical SpecSheet</Link>
            </li>) : null}
          </ul>
          <ul className="navbar-nav">
            <li className="nav-item dropdown ms-auto">
              <a className="nav-link dropdown-toggle" href="/" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                <img className="rounded-circle avatarcls" src={userPhoto} alt='DP' />
                {account ? (<span className='userName'>{userName}</span>) : (<span className='userName'>Unknown User</span>)}
              </a>
              <ul className="dropdown-menu dropdown-menu-lg-end px-2">
                <li className='ditem'>{mail}</li>
                <li className='ditem'>{office}</li>
                <li><hr className="dropdown-divider" /></li>
                <li className='ditem'> <button className="btn btn-secondary" onClick={handleLogout}>Logout</button></li>
              </ul>
            </li>
          </ul>

          {/* <button onClick={handleLogout}>Logout {envs}</button>
        <form className="form-inline my-2 my-lg-0">
          <img className="img-thumbnail rounded-circle avatarcls" style={{ width: '50px', height: '50px' }} src="https://mdbootstrap.com/img/Photos/Avatars/img%20(30).jpg" alt='text' />
          {activeAccount ? (<span className='userName'>{activeAccount.name}</span>) : (<span className='userName'>Unknown User</span>)}
        </form> */}
          {/* <ul className="dropdown-menu dropdown-menu-end">
          <li><a className="dropdown-item" href="#">Action</a></li>
          <li><a className="dropdown-item" href="#">Another action</a></li>
          <li><a className="dropdown-item" href="#">Something else here</a></li>
        </ul> */}
        </div>

      </div>
    </nav>
  );
};

// const Navbar = ({ instance, profile, picture}) => {
//   const [activeLink, setActiveLink] = useState('home');
//   const [name, setName] = useState('');
//   const [mail, setMail] = useState('');
//   const [office, setOffice] = useState('');
//   // const [profile, setProfilepic] = useState();
//   const activeAccount = instance.getActiveAccount();

//   console.log(profile)
//   console.log(picture)
//   // const idata = null;
//   // console.log(profileData)
//   // getUserProfile(instance)
//   // .then(userProfile => {
//   //   // Handle the userProfile data here
//   //   console.log(userProfile)
//   //   // setMail(userProfile.mail)
//   //   // setName(userProfile.displayName)
//   //   // setOffice(userProfile.officeLocation)
//   //   // You can perform further actions with the userProfile data
//   // })
//   // .catch(error => {
//   //   // Handle errors if the promise is rejected
//   //   console.error(error);
//   //   // alert("Somthing went wrong, Login again!")
//   // });

//   // getProfilePhoto(instance)
//   // .then((res)=>{
//   //   setProfilepic(res)
//   // })
//   // .catch(error => {
//   //   // Handle errors if the promise is rejected
//   //   console.error(error);
//   // });
//   const envs = process.env.REACT_APP_ENVIRONMENT;

//   const handleLogout = () => {
//     // Clear the user's session and sign out
//     instance.logout();
//   }

//   const handleLinkClick = (link) => {
//     console.log(link)
//     setActiveLink(link);
//   };
//   return (
//     <nav className="navbar navbar-expand-lg navbar-dark bg-dark">
//       <div className="container-fluid">
//         <a className="navbar-brand" href="#">
//           <img src={FGFLogo} width="50" height="50" className="d-inline-block align-top" alt="FGF" />
//         </a>
//         <button
//           className="navbar-toggler"
//           type="button"
//           data-bs-toggle="collapse"
//           data-bs-target="#navbarSupportedContent"
//           aria-controls="navbarSupportedContent"
//           aria-expanded="false"
//           aria-label="Toggle navigation"
//         >
//           <span className="navbar-toggler-icon"></span>
//         </button>
//         <div className="collapse navbar-collapse" id="navbarSupportedContent">
//           <ul className="navbar-nav me-auto mb-2 mb-lg-0">
//             <li className="nav-item">
//               <Link
//                 className={`nav-link ${activeLink === 'home' ? 'active' : ''}`}
//                 to="/"
//                 onClick={() => handleLinkClick('home')}
//               >Home</Link>
//             </li>
//             <li className="nav-item">
//               <Link
//                 className={`nav-link ${activeLink === 'sales' ? 'active' : ''}`}
//                 to="/sales"
//                 onClick={() => handleLinkClick('sales')}
//               >Sales SpecSheet</Link>
//             </li>
//             <li className="nav-item">
//               <Link
//                 className={`nav-link ${activeLink === 'tech' ? 'active' : ''}`}
//                 to="/tech"
//                 onClick={() => handleLinkClick('tech')}
//               >Techical SpecSheet</Link>
//             </li>
//           </ul>
//           <ul className="navbar-nav">
//             <li className="nav-item dropdown ms-auto">
//               <a className="nav-link dropdown-toggle" href="/" role="button" data-bs-toggle="dropdown" aria-expanded="false">
//               <img className="img-thumbnail rounded-circle avatarcls" style={{ width: '50px', height: '50px' }} src={profile} alt='text' />
//             {activeAccount ? (<span className='userName'>{name}</span>) : (<span className='userName'>Unknown User</span>)}
//               </a>
//               <ul className="dropdown-menu dropdown-menu-lg-end">
//                 <li className='ditem'>{mail}</li>
//                 <li className='ditem'>{office}</li>
//                 <li><hr className="dropdown-divider" /></li>
//                 <li className='ditem'> <button onClick={handleLogout}>Logout</button></li>
//               </ul>
//             </li>
//           </ul>

//           {/* <button onClick={handleLogout}>Logout {envs}</button>
//           <form className="form-inline my-2 my-lg-0">
//             <img className="img-thumbnail rounded-circle avatarcls" style={{ width: '50px', height: '50px' }} src="https://mdbootstrap.com/img/Photos/Avatars/img%20(30).jpg" alt='text' />
//             {activeAccount ? (<span className='userName'>{activeAccount.name}</span>) : (<span className='userName'>Unknown User</span>)}
//           </form> */}
//           {/* <ul className="dropdown-menu dropdown-menu-end">
//             <li><a className="dropdown-item" href="#">Action</a></li>
//             <li><a className="dropdown-item" href="#">Another action</a></li>
//             <li><a className="dropdown-item" href="#">Something else here</a></li>
//           </ul> */}
//         </div>

//       </div>
//     </nav>
//   );
// };

export default Navbar;