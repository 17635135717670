import './App.css';
import { AuthenticatedTemplate, UnauthenticatedTemplate, useMsal, MsalProvider } from '@azure/msal-react';
import { loginRequest } from './auth-config';
import { Route, Routes } from 'react-router-dom';
import SalesSheet from './pages/sales';
import Home from './pages/home';
import TechSheet from './pages/tech';
import Navbar from './components/navbar';

const Wrappedview = () => {
  const {instance} = useMsal();
  // const activeAccount = instance.getActiveAccount();


  const handleRedirect = () => {
    instance.loginRedirect({ ...loginRequest, prompt:'create'}).catch((error)=>console.warn(error));
  };

    return (
      <div className="App">
          <AuthenticatedTemplate>
            <Navbar/>
            <Routes>        
              <Route path="/"  element={<Home/>} />
              <Route path="/sales"  element={<SalesSheet/>} />
              <Route path="/tech"  element={<TechSheet />}/>
            </Routes>
          </AuthenticatedTemplate>
          <UnauthenticatedTemplate>
            {handleRedirect}
          </UnauthenticatedTemplate>
      </div>
    );

  };

const App = ({ instance }) => {
  return (
    <MsalProvider instance={instance}>
      <Wrappedview />
    </MsalProvider>
  )
}

export default App;
