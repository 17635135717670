
const eng_labels = {
    "item_No": "Item No.",
    "item_UPC_EAN_Number": "UPC",
    "cross_Reference_No": "SCC",
    "baked_Weight": "Baked Weight per Piece",
    "unit_Weight": "Package Weight",
    "packaging_description": "Packaging Description",
    "pieces_Per_Pack": "Pieces per Package",
    "pack_Per_Case": "Packages per Case",
    "t_x_T": "Tie by Tier",
    "net_Case_Weight": "Net Case Weight",
    "gross_Case_Weight": "Gross Case Weight",
    "case_Length_Outer": "Case L",
    "case_Width_Outer": "Case W",
    "case_Height_Outer": "Case H",
    "cubic_Feet": "Cubic cm",
    "expiration_Calculation": "Frozen Life",
    "cust_Expected_Shelf_Days_Ambient": "Shelf Life Ambient",
    "cust_Expected_Shelf_Days_Refridgerated": "Shelf Life Refrigerated",
    "certification_Name": "Certification"
};
const fr_labels = {
    "item_No": "Numéro de produit",
    "item_UPC_EAN_Number": "Code de produit universel",
    "cross_Reference_No": "Code de produit caisse",
    "baked_Weight": "Poids cuit par pièce",
    "unit_Weight": "Poids du paquet",
    "packaging_description": "Description des unités",
    "pieces_Per_Pack": "Nombre d'unités par paquet",
    "pack_Per_Case": "Unités par caisse",
    "t_x_T": "Palletisation",
    "net_case_Width_Outereight": "Poids caisse net",
    "gross_Case_Weight": "Poids caisse brut",
    "case_Length_Outer": "Longueur Caisse",
    "Case_W": "Largeur Caisse",
    "case_Height_Outer": "Hauteur Caisse",
    "cubic_Feet": "Centimètre cube",
    "expiration_Calculation": "Durée de vie surgelée",
    "cust_Expected_Shelf_Days_Ambient": "Durée de conservation ambiante",
    "cust_Expected_Shelf_Days_Refridgerated": "Durée de conservation Réfrigéré",
    "certification_Name": "Certification"
}


const metric_units = {
    "baked_Weight": "g",
    "unit_Weight": " g",
    "net_Case_Weight": " kg",
    "gross_Case_Weight": " kg",
    "case_Length_Outer": " cm",
    "case_Width_Outer": " cm",
    "case_Height_Outer": " cm",
    "expiration_Calculation": " days",
    "cust_Expected_Shelf_Days_Ambient": " days",
    "cust_Expected_Shelf_Days_Refridgerated": " days",
}

const imperial_units = {
    "baked_Weight": "oz",
    "unit_Weight": " oz",
    "net_Case_Weight": " lbs",
    "gross_Case_Weight": " lbs",
    "case_Length_Outer": " in",
    "case_Width_Outer": " in",
    "case_Height_Outer": " in",
    "expiration_Calculation": " days",
    "cust_Expected_Shelf_Days_Ambient": " days",
    "cust_Expected_Shelf_Days_Refridgerated": " days",
}


const getUnit = (unitType, key) =>{
    if(unitType==="metric")
    {
        return metric_units[key] || ""
    }
    else
    {
        return imperial_units[key] || ""
    }
}

const getLabel = (locale, sublocale, key) => {
    let label = ""
    if(locale===sublocale)
    {
        if(locale==="fr")
        {
            label+=fr_labels[key]
        }
        else
        {
            label+=eng_labels[key]
        }

        return label
    }
    if(locale==="fr")
    {
        label+=fr_labels[key]
    }
    else
    {
        label+=eng_labels[key]
    }

    if(sublocale==='fr')
    {
        label= label + "/" + fr_labels[key]

    }
    else if (sublocale==='en')
    {
        label= label + "/" + eng_labels[key]
    }
    else{
        label+=""

    }

    return label
}

const showEnterobacteriaea = (customerNames, countryRegionCodes) => {

    if(!customerNames || !countryRegionCodes)
    {
        return false
    }
    // Convert comma-separated strings into arrays for easier manipulation
    const customerNameArray = customerNames.split(',');
    const countryRegionCodeArray = countryRegionCodes.split(',');
  
    // Check if "Publix" is in the customer names
    const containsPublix = customerNameArray.some(name => name.includes("Publix"));
    if (containsPublix) {
      return false;
    }
  
    // Check if "Aldi" is in the customer names and "AU" is in the country region codes
    const containsAldi = customerNameArray.some(name => name.includes("Aldi"));
    const containsAU = countryRegionCodeArray.some(code => code.includes("AU"));
    if (containsAldi && containsAU) {
      return false;
    }
  
    // If none of the conditions are met, return true
    return true;
  }
//BenzoicAcid, TBHQ, SorbicAcid, Sulphurdioxide, Artificial Coloring, Sudan, Aflatoxin
  const showAcids = (customerNames) => {
    if(!customerNames)
    {
        return false
    }
    // Split the customer names into an array for easier checking
    const customerNameArray = customerNames.split(',');
  
    // Check if "Kobe Bussan" is in the customer names
    const containsKobeBussan = customerNameArray.some(name => name.includes("Kobe Bussan"));
  
    // Return true if "Kobe Bussan" is not found, which aligns with the SSRS logic
    return window.location.href.includes("devrxanalytics") || !containsKobeBussan;
  }
  


export {getLabel, eng_labels, getUnit, showEnterobacteriaea, showAcids}