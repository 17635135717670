import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSheetPlastic } from '@fortawesome/free-solid-svg-icons'
import { faClipboardList } from '@fortawesome/free-solid-svg-icons';
import '../styles/home.css'
import { Link, useNavigate } from 'react-router-dom';
import { useState } from 'react';
import { useLink } from '../LinkContext';

const Home = () => {
    const [techCard, setTechCard] = useState(true)
    const { activeLink,handleLinkClick } = useLink();
    const navigate = useNavigate(); // For React Router v6

    const handleCardClick = () => {
        // Your pre-navigation logic here
        console.log("Navigating to /sales");
        handleLinkClick('sales')

        // Navigate to the desired route
        navigate('/sales');
    };
    return (
        <div className="container d-flex justify-content-center align-items-center" style={{ height: '80vh' }}>
            <div className="row">
                <div className="col-md-6 cardCol" onClick={handleCardClick}>
                    
                        <div className="card">
                            <div className="card-body">
                                <h5 className="card-title">Sales Spec Sheet</h5>
                                <div className="iconcls"><FontAwesomeIcon icon={faSheetPlastic} size='3x' /></div>
                                <p className="card-text">Create and share sales spec sheet for FGF products.</p>
                            </div>
                        </div>
                  
                </div>
                {techCard? (<div className="col-md-6 cardCol">
                    <Link to="/tech" className="homeLink">
                        <div className="card">
                            <div className="card-body">
                                <h5 className="card-title">Tech specs sheet</h5>
                                <div className="iconcls"><FontAwesomeIcon icon={faClipboardList} size='3x' /> </div>
                                <p className="card-text">Create and share tech spec sheet for FGF products.</p>
                            </div>
                        </div>
                    </Link>
                </div>):null}
                
            </div>
        </div>
    );
};

export default Home;