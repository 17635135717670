import React, { useEffect, useState } from 'react';
import '../styles/techReport.css';
// import nutriIMage from '../static/nfacts.png';
import Logo from '../static/logos/STONEFIRE.png';
import { getTechnicalData, getImage } from '../services/dataservice';
import { showEnterobacteriaea, showAcids } from '../services/labels';



const TechReport = ({ productID, allergen, nutriInfo, weighUnit, lengthUnit, gmo, ingper100, isPDFReady }) => {
    const [loading, setLoading] = useState(true);
    const [techData, setTechData] = useState(null);
    const [nutriImage, setNutriImg] = useState(null);
    const [nutriImage100, setNutriImg100] = useState(null);
    const [nodata, setNodata] = useState(false)

    const formatDate = () => {
        let date = new Date();
        const day = date.toLocaleString('default', { day: '2-digit' });
        const month = date.toLocaleString('default', { month: 'short' });
        const year = date.toLocaleString('default', { year: 'numeric' });
        console.log(day + '-' + month + '-' + year)
        return day + '-' + month + '-' + year;
    }
    useEffect(() => {
        console.log("UEF called")
        let ignore = false
        setLoading(true)

        getTechnicalData(productID, weighUnit, lengthUnit).then((res) => {
            if (!ignore) {
                setTechData(res)
                setLoading(false)
                isPDFReady(true)
                console.log(res)
            }
        }).catch((e) => {
            setLoading(true)
            if(!ignore)
            {
                setNodata(true)
                alert(`No data available for item${productID}. Try other Item.`)
            }
        });

        // getImage(productID, "a", "small").then((d) => {
        //     setFrontImg(d)
        // })

        // getImage(productID, "b", "small").then((d) => {
        //     setBackImg(d)
        // })

        getImage(productID, "itemnutrition", "clear").then((d) => {
            setNutriImg(d)
        })

        getImage(productID + "_100", "itemnutrition", "clear").then((d) => {
            setNutriImg100(d)
        })

        return () => {
            ignore = true
        }
    }, [allergen, nutriInfo, weighUnit, lengthUnit, gmo, ingper100]);

    if(nodata)
    {
        return (<><p className='noData'>Report not available for item {productID}. Try other Item.</p></>)
    }


    return (
        <>
            {loading ? <div className='d-flex justify-content-center'> <span className='loader'></span> </div> : <div className='container techReport' id='techReport'><table className='table mainTable'>
                <thead>
                    <tr>
                        <td colSpan={2}>
                            <div className='ReportHeader' id='head'>
                                <div className='BrandLogo pe-3 me-auto'>
                                    <img src={Logo} alt="Image not found" className='BrandLogo' />
                                </div>
                                <div className='TechReportTitle ps-1'>PRODUCT SPECIFICATION SHEET</div>
                                <div className='ms-auto dateTopLeft'>Printed Date: {formatDate()}</div>
                            </div>
                        </td>
                    </tr>
                </thead>
                <tbody>
                    <div id='mainTechReport' className='mainTechReport'>
                        <tr>
                            <td><b>Product code:</b> {productID}</td>
                            <td></td>
                        </tr>
                        <tr>
                            <td> <b>UPC code:</b> {techData.itemUPC_EANNumber ?? ''}</td>
                            <td> <b>Manufacturing country:</b> Product Of {techData.sourceCountry ?? ''}</td>
                        </tr>
                        <tr>
                           
                            <td><b>SCC/GTIN code:</b>{techData.cross_Reference_No ?? ''}</td>
                            <td></td>
                        </tr>
                        <tr>
                            <td><b>Product Name:</b> {techData.caseLabelDescription ?? ''}</td>
                            <td> <b>Destination country:</b> {techData.destinationCountry ?? ''} </td>
                        </tr>
                        <tr>
                            <td className='mainHalf'>
                                <strong> Ingredients: (In Accordaance with United States of America Legislation) </strong>
                                <p className='textSize'>{techData.caseIngredients ?? ''}</p>

                                <strong>QUANTITATIVE PARAMETERS:</strong>
                                <table className='table table-4-col'>
                                    <tr className='green-back'>
                                        <td></td>
                                        <td >Target</td>
                                        <td>Tolerance (+)</td>
                                        <td>Tolerance (-)</td>
                                    </tr>
                                    <tr>
                                        <td className='grey-back'>Diameter</td>
                                        <td>{techData.diameter ?? ''} {lengthUnit}</td>
                                        <td>{techData.diameterTolMax ?? ''} {lengthUnit}</td>
                                        <td>{techData.diameterTolMin ?? ''} {lengthUnit}</td>
                                    </tr>
                                    <tr>
                                        <td className='grey-back'>Thickness</td>
                                        <td>{techData.thickness ?? ''} {lengthUnit}</td>
                                        <td>{techData.thicknessTolMax ?? ''} {lengthUnit}</td>
                                        <td>{techData.thicknessTolMin ?? ''} {lengthUnit}</td>
                                    </tr>
                                    <tr>
                                        <td className='grey-back'>Height</td>
                                        <td>{techData.weight ?? ''} {lengthUnit}</td>
                                        <td>{techData.weightTolMax ?? ''} {lengthUnit}</td>
                                        <td>{techData.weightTolMin ?? ''} {lengthUnit}</td>
                                    </tr>
                                    <tr>
                                        <td className='grey-back'>Width</td>
                                        <td>{techData.width ?? ''}{lengthUnit}</td>
                                        <td>{techData.widthTolMax ?? ''} {lengthUnit}</td>
                                        <td>{techData.widthTolMin ?? ''} {lengthUnit}</td>
                                    </tr>
                                    <tr>
                                        <td className='grey-back'>Length</td>
                                        <td>{techData.length ?? ''} {lengthUnit}</td>
                                        <td>{techData.lengthTolMax ?? ''} {lengthUnit}</td>
                                        <td>{techData.lengthTolMin ?? ''} {lengthUnit}</td>
                                    </tr>
                                    <tr>
                                        <td className='grey-back'>Package Weight</td>
                                        <td>{techData.weight ?? ''} {weighUnit}</td>
                                        <td>{techData.weightTolMax ?? ''} {weighUnit}</td>
                                        <td>{techData.weightTolMin ?? ''} {weighUnit}</td>
                                    </tr>
                                    <tr>
                                        <td className='grey-back'>Pieces per Package</td>
                                        <td>{techData.piecesPerPack ?? ''}</td>
                                        <td>N/A</td>
                                        <td>N/A</td>
                                    </tr>
                                    <tr>
                                        <td className='grey-back'>Packages per Case</td>
                                        <td>{techData.packsPerCase ?? ''}</td>
                                        <td>N/A</td>
                                        <td>N/A</td>
                                    </tr>
                                </table>

                                <table className='table table-3-col'>
                                    <thead>
                                        <tr>
                                            <td className='grey-back' colSpan={3} ><strong>MICROBIOLOGICAL REQUIREMENTS:</strong></td>
                                        </tr>
                                        <tr>
                                            <th className='green-back'>Analysis</th>
                                            <th className='green-back'>MAX LIMIT</th>
                                            <th className='green-back'>METHOD</th>
                                        </tr>
                                    </thead>

                                    <tbody>

                                        <tr>
                                            <td>Aerobic Colony Count</td>
                                            <td>&lt;10000 cfu/g</td>
                                            <td>MFHPB-18</td>
                                        </tr>
                                        <tr>
                                            <td>Total Coliforms</td>
                                            <td>&lt;100 cfu/g</td>
                                            <td>MFHPB-34</td>
                                        </tr>
                                        <tr>
                                            <td>E. Coli</td>
                                            <td>&lt;10 cfu/g</td>
                                            <td>MFHPB-34</td>
                                        </tr>
                                        <tr>
                                            <td>Staphylococcus aureus</td>
                                            <td>&lt;100 cfu/g</td>
                                            <td>MFLP-21</td>
                                        </tr>
                                        <tr>
                                            <td>Yeast & Mold</td>
                                            <td>&lt;100 cfu/g</td>
                                            <td>MFHPB-22</td>
                                        </tr>
                                        <tr>
                                            <td>Salmonella</td>
                                            <td>Negative</td>
                                            <td>MFHPB-20</td>
                                        </tr>
                                        <tr>
                                            <td>Listeria</td>
                                            <td>Negative</td>
                                            <td>MFHPB-30</td>
                                        </tr>
                                        {showEnterobacteriaea(techData.customerNames, techData.countryRegionCode) && (<tr>
                                            <td>Enterobacteriaea</td>
                                            <td>&lt;100 cfu/g</td>
                                            <td>MFLP-09</td>
                                        </tr>)}
                                        {showAcids(techData.customerNames) && (<><tr>
                                            <td>Benzoic Acid</td>
                                            <td>Not detected</td>
                                            <td>CAM SOP-00740</td>
                                        </tr>
                                        <tr>
                                            <td>TBHQ</td>
                                            <td>Not detected</td>
                                            <td>JAOAC1996.79(6) 1459</td>
                                        </tr>
                                        <tr>
                                            <td>Sorbic Acid</td>
                                            <td>Not detected</td>
                                            <td>CAM SOP-00740</td>
                                        </tr>
                                        <tr>
                                            <td>Sulphur dioxide</td>
                                            <td>Not detected</td>
                                            <td>CAM SOP-00718</td>
                                        </tr>
                                        <tr>
                                            <td>Artificial coloring</td>
                                            <td>Not detected</td>
                                            <td>M-H422a</td>
                                        </tr>
                                        <tr>
                                            <td>Sudan</td>
                                            <td>Not detected</td>
                                            <td>M-H422</td>
                                        </tr>
                                        <tr>
                                            <td>Aflatoxin</td>
                                            <td>Not detected</td>
                                            <td>CAM SOP-00734</td>
                                        </tr></>) }

                                        
                                    </tbody>

                                </table>


                            </td>
                            <td>
                                <strong>ORGANOLEPTIC PROPERTIES</strong>
                                <table className='table table-2-col'>
                                    <thead>
                                        <tr>
                                            <th colSpan="2">Product Attributes and dimentions</th>
                                        </tr>
                                    </thead>

                                    <tbody>
                                        <tr>
                                            <td className="green-back">Appearance / Colour </td>
                                            <td>{techData.appearanceAcc ?? ''}</td>
                                        </tr>
                                        <tr>
                                            <td className="green-back">Taste</td>
                                            <td>{techData.flavorAcc ?? ''}</td>
                                        </tr>
                                        <tr>
                                            <td className="green-back">Texture</td>
                                            <td>{techData.textureAcc ?? ''}</td>
                                        </tr>
                                        <tr>
                                            <td className="green-back">Aroma</td>
                                            <td>{techData.aromaAcc ?? ''}</td>
                                        </tr>
                                    </tbody>
                                </table>

                                <strong>LOT TRACEBILITY EXPLANATION</strong>
                                <table>
                                    <tr><td><b>Date Code per Master case:</b> {techData.dateFormat ?? ''}</td></tr>
                                    <tr><td><b>Date Code per Retail Unit:</b>{techData.dateCodeFormat_Report ?? ''}</td></tr>
                                    <tr><td><b>Package Description :</b>  {techData.packagingdescription ?? ''}</td></tr>
                                    <tr><td><b>Certifications :</b>  {techData.certificationName ?? ''}</td></tr>
                                    <tr><td><strong>STORAGE CONDITIONS AND SHELF LIFE</strong></td></tr>
                                    <tr><td><b>{techData.storage ?? ''}:</b> {techData.shelfLifeFrZN ?? ''} Days</td></tr>
                                    <tr><td><b>Shelf Life - Ambient:</b>  {techData.custExpectedShelfDaysAmbient ?? ''}</td></tr>
                                    {gmo && <tr><td><b>GMO Status:</b>  {techData.charactersticvaluedescription == "" ? 'Unspecified' : techData.charactersticvaluedescription}</td></tr>}
                                </table>
                            </td>
                        </tr>
                        <tr>
                            <td colSpan={2}>
                                {(nutriInfo || ingper100) && (<strong>NUTRITIONAL INFORMATION</strong>)}
                                <div className='nutriDiv'>
                                    {nutriInfo && <img src={nutriImage} className='nutriImage' />}
                                    {ingper100 && <img src={nutriImage100} className='nutriImage' />}
                                </div>
                            </td>
                        </tr>
                        {allergen && (<tr>
                            <td colSpan={2}>
                                <strong>ALLERGEN CHART</strong>

                                <table className='table-4-col-allergen'>
                                    <tr>
                                        <th className='light-green-back green-back'>Allergen</th>
                                        <th className='small-cols green-back'>Present In Product</th>
                                        <th className='small-cols green-back'>Present in Line</th>
                                        <th className='small-cols green-back'>Present in Same Plant</th>
                                    </tr>
                                    <tr>
                                        <td className='light-green-back'>Eggs or Egg Derivatives</td>
                                        <td className="green-background">{techData.allergen.includes('E') ? 'YES' : 'NO'}</td>
                                        <td className="green-background">{techData.plantAllergen.includes('E') ? 'YES' : 'NO'}</td>
                                        <td className="green-background">{techData.plantAllergen.includes('E') ? 'YES' : 'NO'}</td>
                                    </tr>
                                    <tr>
                                        <td className='light-green-back'>Milk or Milk Derivatives (lactose, caseinates, whey, etc.)</td>
                                        <td className="green-background">{techData.allergen.includes('D') ? 'YES' : 'NO'}</td>
                                        <td className="green-background">{techData.plantAllergen.includes('D') ? 'YES' : 'NO'}</td>
                                        <td className="green-background">{techData.plantAllergen.includes('D') ? 'YES' : 'NO'}</td>
                                    </tr>
                                    <tr>
                                        <td className='light-green-back'>Soy (including flour, protein, lecithin, organic soya oil - not including refined bleached deodorized oil)</td>
                                        <td className="green-background">{techData.allergen.includes('S') ? 'YES' : 'NO'}</td>
                                        <td className="green-background">{techData.plantAllergen.includes('S') ? 'YES' : 'NO'}</td>
                                        <td className="green-background">{techData.plantAllergen.includes('S') ? 'YES' : 'NO'}</td>
                                    </tr>
                                    <tr>
                                        <td className='light-green-back'>Wheat</td>
                                        <td className="green-background">{techData.allergen.includes('W') ? 'YES' : 'NO'}</td>
                                        <td className="green-background">{techData.plantAllergen.includes('W') ? 'YES' : 'NO'}</td>
                                        <td className="green-background">{techData.plantAllergen.includes('W') ? 'YES' : 'NO'}</td>
                                    </tr>
                                    <tr>
                                        <td className='light-green-back'>Other Gluten Sources (barley, rye, oats, triticale, kamut, spelt)</td>
                                        <td className="green-background">{techData.allergen.includes('O') ? 'YES' : 'NO'}</td>
                                        <td className="green-background">{techData.plantAllergen.includes('O') ? 'YES' : 'NO'}</td>
                                        <td className="green-background">{techData.plantAllergen.includes('O') ? 'YES' : 'NO'}</td>
                                    </tr>
                                    <tr>
                                        <td className='light-green-back'>Peanuts or Peanut Derivatives</td>
                                        <td className="green-background">{techData.allergen.includes('P') ? 'YES' : 'NO'}</td>
                                        <td className="green-background">{techData.plantAllergen.includes('P') ? 'YES' : 'NO'}</td>
                                        <td className="green-background">{techData.plantAllergen.includes('P') ? 'YES' : 'NO'}</td>
                                    </tr>
                                    <tr>
                                        <td className='light-green-back'>Tree Nuts (Almonds, brazil nuts, cashews, hazelnuts (filberts), macadamia nuts, pecans, pine nuts (pignolias), pistachio nuts, walnuts, coconut)</td>
                                        <td className="green-background">{techData.allergen.includes('T') ? 'YES' : 'NO'}</td>
                                        <td className="green-background">{techData.plantAllergen.includes('T') ? 'YES' : 'NO'}</td>
                                        <td className="green-background">{techData.plantAllergen.includes('T') ? 'YES' : 'NO'}</td>
                                    </tr>
                                    <tr>
                                        <td className='light-green-back'>Sesame Seeds or Sesame Derivatives</td>
                                        <td className="green-background">{techData.allergen.includes('SS') ? 'YES' : 'NO'}</td>
                                        <td className="green-background">{techData.plantAllergen.includes('SS') ? 'YES' : 'NO'}</td>
                                        <td className="green-background">{techData.plantAllergen.includes('SS') ? 'YES' : 'NO'}</td>
                                    </tr>
                                    <tr>
                                        <td className='light-green-back'>Fish, Crustaceans, Shellfish</td>
                                        <td className="green-background">{techData.allergen.includes('F') ? 'YES' : 'NO'}</td>
                                        <td className="green-background">{techData.plantAllergen.includes('F') ? 'YES' : 'NO'}</td>
                                        <td className="green-background">{techData.plantAllergen.includes('F') ? 'YES' : 'NO'}</td>
                                    </tr>
                                    <tr>
                                        <td className='light-green-back'>Sulphites (&gt;10ppm)</td>
                                        <td className="green-background">{techData.allergen.includes('U') ? 'YES' : 'NO'}</td>
                                        <td className="green-background">{techData.plantAllergen.includes('U') ? 'YES' : 'NO'}</td>
                                        <td className="green-background">{techData.plantAllergen.includes('U') ? 'YES' : 'NO'}</td>
                                    </tr>
                                    <tr>
                                        <td className='light-green-back'>Mustard or Mustard Derivatives</td>
                                        <td className="green-background">{techData.allergen.includes('MU') ? 'YES' : 'NO'}</td>
                                        <td className="green-background">{techData.plantAllergen.includes('MU') ? 'YES' : 'NO'}</td>
                                        <td className="green-background">{techData.plantAllergen.includes('MU') ? 'YES' : 'NO'}</td>
                                    </tr>
                                </table>
                                <p className='statement'>ALL PRODUCTS ARE MANUFACTURED UNDER SANITARY CONDITIONS FOLLOWING GOOD MANUFACTURING PRACTICES AND MEET ALL THE APPLICABLE REGULATORY REQUIREMENTS.</p>
                            </td>
                        </tr>)}
                    </div>
                </tbody>
                <tfoot>
                    <tr>
                        <td colSpan={2}>
                            <div className='d-flex' id='foot'>
                                <div className='FooterAddress'>
                                    <p className='FooterData'>1295 Ormont Dr, North York, Ontario M9L 2W6</p>
                                    <p className='FooterData'>Tel: 905-761 3333</p>
                                    <p className='FooterData'>Fax: 905-761 3337</p>
                                    <p className='FooterData'> <u>www.fgfbrands.com www.stonefire.com</u></p>
                                </div>
                                <div className='ms-auto'>
                                    <p>Last Modified:</p>
                                    <p><b>Text Field</b> {techData.lastUpdate ?? ''}</p>
                                    <p><b>Image Field</b>  {techData.modifiedDate ?? ''}</p>
                                </div>
                            </div>

                        </td>
                    </tr>
                </tfoot>
            </table></div>}
        </>
    );
};

export default TechReport;