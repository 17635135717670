import React, { useEffect, useState } from 'react';
import '../styles/tech.css';
import TechReport from '../components/TechReport';
import Select from "react-select/async";
import debounce from "debounce-promise";
import axios from "axios";
import { useSearchParams } from 'react-router-dom';
import { getJWTToken } from '../services/authservice';
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';




const getOptions = debounce(async (inputValue) => {
    try {
        // Always fetch, but if there's no inputValue, don't use the 'name_like' filter
        const token = localStorage.getItem('ItemToken'); // Assuming the token is stored in localStorage
        // Set up Axios headers
        const headers = {
            Authorization: `Bearer ${token}`,
        };
        const query = inputValue ? `&searchText=${inputValue}` : "";

        const response = await axios.get(
            `${process.env.REACT_APP_DATA_API}api/Items?page=1&pageSize=10${query}`,
            {headers}
        );

        // Check if the response data is an array before proceeding
        if (Array.isArray(response.data)) {
            const items = response.data.slice(0, 10).map((item) => ({
                // Slice to get only 10 results
                value: item.item_No,
                label: item.item_No + " - " + item.description,
            }));
            return items;
        } else {
            // Handle the case where data is not an array
            console.error('Response data is not an array');
            return [];
        }
    } catch (error) {
        // Handle errors here
        console.error('There was an error fetching the options:', error);
        return [];
    }
}, 1000);

const TechSheet = () => {
    const [searchParams, setSearchParams] = useSearchParams();
    const [pid, setpID] = useState()
    const [defaultOptions, setDefaultOptions] = useState([]);
    const [allergen, setAllergen] = useState(true);
    const [nutriInfo, setNutriInfo] = useState(true);
    const [weighUnit, setWeighUnit] = useState("gs");
    const [lengthUnit, setLengthUnit] = useState("mms");
    const [gmo, setGmo] = useState(true);
    const [ingper100, setIngper100]= useState(true);
    const [submit, setSubmit] = useState(false);
    const [exportPDF, setExportPDF] = useState(false);


    const exportTOPDF = () => {
        // First, capture the header, content, and footer elements separately
        Promise.all([
            html2canvas(document.querySelector("#head"), {scale: 3}),
            html2canvas(document.querySelector("#mainTechReport"), {scale: 3}),
            html2canvas(document.querySelector("#foot"), {scale: 3})
        ]).then(canvases => {
            const [headCanvas, contentCanvas, footCanvas] = canvases;
            
            // Initialize jsPDF
            const pdf = new jsPDF('p', 'mm', 'a4'); // Adjust page size as needed
    
            const imgData = contentCanvas.toDataURL('image/png');
            const headerData = headCanvas.toDataURL('image/png');
            const footerData = footCanvas.toDataURL('image/png');
    
            // Dimensions of the A4 page
            const pageWidth = 210; // A4 width in mm
            const pageHeight = 250;  // A4 height in mm
    
            // Calculate content dimensions to fit the page width
            const imgHeight = (contentCanvas.height * pageWidth / contentCanvas.width);
            let heightLeft = imgHeight;
    
            const headerHeight = 25; // Adjust based on your actual header height
            const footerHeight = 30; // Adjust based on your actual footer height
            let position = 0;
    
            const addHeaderFooter = () => {
                // Add header
                pdf.addImage(headerData, 'PNG', 0, 0, pageWidth, headerHeight, undefined, 'FAST');
                // Add footer
                pdf.addImage(footerData, 'PNG', 0, 297 - footerHeight, pageWidth, footerHeight, undefined, 'FAST');
            };
    
            // Add content and header/footer to the first page
            pdf.addImage(imgData, 'PNG', 0, headerHeight, pageWidth, imgHeight, undefined, 'FAST');
            addHeaderFooter(); // Call function to add header and footer
            heightLeft -= pageHeight;
    
            // Add new pages for content that overflows
            let i=1
            while (heightLeft >= 0) {
                position = (heightLeft - imgHeight)+ 8*i; // Adjust position for footer
                pdf.addPage();
    
                // Add the image first to avoid overlay issues
                pdf.addImage(imgData, 'PNG', 0, position + headerHeight, pageWidth, imgHeight, undefined, 'FAST');
                addHeaderFooter(); // Call function to add header and footer again for new page
                
                heightLeft -= pageHeight;
                i++;
            }
    
            // Save the PDF
            pdf.save(`Technical_SpecSheet_${pid.value??''}.pdf`);
        });
    };
    
    const isPDFReady = (val) =>{
        setExportPDF(val)
    }


    
    const handleChange = (e) => {
        setSubmit(false)
        isPDFReady(false)
        setpID({
            value: e.value,
            label: e.label, 
        });
    };

    const convertOnOffToTF = (str) =>{
        if(str.toLowerCase()==="on")
        {
            return true
        }
        return false;
    }

    const viewClicked = () =>{
        if(pid)
        {
            setSubmit(true)
        }
    }

    const updateAllergen = (e) =>{
        setAllergen(convertOnOffToTF(e.target.value))
    }
    useEffect(()=>{
        getJWTToken().then((data) => {
            localStorage.setItem("ItemToken", data)
        })
        // handleOptions(data)
        if (searchParams.get("id")) {
            const ParamID = searchParams.get("id")
            getOptions(ParamID).then((options) => {
                setpID({
                    value: options[0].value,
                    label: options[0].label
                })
            })
        }
        else{
            getOptions("").then((options) => {
                setDefaultOptions(options);
            })
        }
    },[searchParams]);
    return (
        <div>
            <div className='container-fluid'>
                <div className='row mainInputItem'>
                    <div className='col col-md-9'>
                        <div className='row'>
                            <div className='col col-md-12 inputDivTech'>

                            <div className='Inputtitles'>Enter Product ID (SKU)</div>
                            <Select
                                cacheOptions
                                defaultOptions={defaultOptions}
                                loadOptions={getOptions}
                                value={pid}
                                placeholder="Start typing to search..."
                                onChange={handleChange}
                                className='selectbarTech'
                                styles={{width:"100px"}}
                            />
                            </div>
                        </div>
                        <div className='row'>
                            <div className='col col-md-6 inputDiv'>
                            <span className='Inputtitles'> Turn allergen on/off</span>
                            <select className="form-select selectbar2" onChange={updateAllergen}>
                                <option value="on">On</option>
                                <option value="off">Off</option>
                            </select>
                            </div>
                           <div className='col col-md-6 inputDiv'>
                            <span className='Inputtitles'> Nutritional information</span>
                            <select className="form-select selectbar2" onChange={(e)=>{setNutriInfo(convertOnOffToTF(e.target.value))}}>
                                <option value="on">On</option>
                                <option value="off">Off</option>
                            </select>
                            </div>
                        </div>
                        <div className='row'>
                        <div className='col col-md-6 inputDiv'>
                            <span className='Inputtitles'> Nutritions per 100 g</span>
                            <select className="form-select selectbar2" onChange={(e)=>{setIngper100(convertOnOffToTF(e.target.value))}}>
                                <option value="on">On</option>
                                <option value="off">Off</option>
                            </select>
                            </div>
                            <div className='col col-md-6 inputDiv'>
                            <span className='Inputtitles'> grams or lbs</span>
                            <select className="form-select selectbar2" onChange={(e)=>{setWeighUnit(e.target.value)}}>
                                <option value="gs">grams</option>
                                <option value="lbs">lbs</option>
                            </select>
                            </div>
                        </div>
                        <div className='row'>
                            <div className='col col-md-6 inputDiv'>
                            <span className='Inputtitles'> select mm or inches</span>
                            <select className="form-select selectbar2" onChange={(e)=>{setLengthUnit(e.target.value)}}>
                                <option value="mms">mm</option>
                                <option value="inches">inches</option>
                            </select>
                            </div>
                            <div className='col col-md-6 inputDiv'>
                            <span className='Inputtitles'> turn GMO on/off</span>
                            <select className="form-select selectbar2" onChange={(e)=>{setGmo(convertOnOffToTF(e.target.value))}}>
                                <option value="on">On</option>
                                <option value="off">Off</option>
                            </select>
                            </div>
                        </div>
                    </div>
                    <div className='col col-md-3 buttonsCol'>
                        <button className='btn viewBtn btn-margin' onClick={viewClicked}>View report</button>
                        {exportPDF && <button className='btn downBtn btn-margin' onClick={exportTOPDF}>Download</button>}
                    </div>
                </div>
            </div>
            {/* {productID, allergen, nutriInfo, weighUnit, lengthUnit, gmo, ingper100} */}
            {submit && (<TechReport 
            productID={pid.value}
            allergen={allergen}
            nutriInfo={nutriInfo}
            weighUnit={weighUnit}
            lengthUnit={lengthUnit}
            gmo={gmo}
            ingper100={ingper100}
            isPDFReady = {isPDFReady}
            />)}
        </div>
    );
};

export default TechSheet;