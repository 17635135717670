import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import { PublicClientApplication, EventType } from '@azure/msal-browser';
import { msalConfig } from './auth-config';
import { BrowserRouter } from 'react-router-dom';
import { LinkProvider } from './LinkContext';

const msalInstance = new PublicClientApplication(msalConfig);

msalInstance.initialize().then(() => {
  // Account selection logic is app dependent. Adjust as needed for different use cases.
  const accounts = msalInstance.getAllAccounts();
  if (accounts.length > 0) {
      msalInstance.setActiveAccount(accounts[0]);
  }

  msalInstance.addEventCallback((event) => {
    console.log(event.eventType);
      if (event.eventType === EventType.LOGIN_SUCCESS && event.payload) {
          const payload = event.payload;
          const account = payload.account;
          msalInstance.setActiveAccount(account);
      }
      if (event.eventType === EventType.LOGIN_FAILURE || event.errorCode === 'token_renewal_error') {
        alert("Session expired or Login failed. Please reload the page.");
    }
  });

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <BrowserRouter>
    <LinkProvider>
      <App instance={msalInstance} />
    </LinkProvider>
    </BrowserRouter>
  </React.StrictMode>
);
});

// if(msalInstance.getActiveAccount() && msalInstance.getAllAccounts().length>0)
// {
//   msalInstance.setActiveAccount(msalInstance.getActiveAccount()[0]);
//   console.log("active acc is set");
// }

// msalInstance.addEventCallback((event)=>{
//   if(event.eventType===EventType.LOGIN_SUCCESS && event.payload.account){
//     const account = event.payload.account;
//     msalInstance.setActiveAccount(account);
//   }
// })
// const root = ReactDOM.createRoot(document.getElementById('root'));
// root.render(
//   <React.StrictMode>
//     <App instance={msalInstance} />
//   </React.StrictMode>
// );

